.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    /*background-color: #f0f0f0;*/
    border-radius: 10px;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.modal {
    position: relative;
    background-color: white;
    padding: 20px;
    z-index: 10000;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }

  .generated-image {
    max-width: 100%;
    height: auto;
  }

  .disabled-button {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }

  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

form {
    display: grid;
    gap: 10px;
  }

input,
  textarea,
  button {
    padding: 10px;
    font-size: 16px;
  }

button {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }

button:hover {
    background-color: #45a049;
  }

label {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    display: block;
  }

input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

h3 {
    font-size: 22px;
    margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

label {
  margin-bottom: 10px;
}

Input {
  width: 100%;
}

.pages-ul .common-heading {
  text-align: unset !important;
}