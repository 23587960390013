body {
  margin: 0;
  font-family: "Times New Roman" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h3, h2 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: normal !important;
  font-family: 'Open Sans', sans-serif !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-80 {
  width: 80% !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.text-center {
  text-align: center;
}

.ant-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translate(0, 0);
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 15px;
  margin-top: 3rem;
}

.ant-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translate(0, -4px);
}

label {
  display: block !important;
}

.select-custom {
  width: 100%;
  padding: 10px;
}

.select-custom .ant-select-arrow {
  margin-right: 10px !important;
}

.button {
  padding: 15px;
}

.ant-btn.ant-btn-round.ant-btn-lg {
  border-radius: 40px;
  padding-inline-start: 60px;
  padding-inline-end: 60px;
  font-size: large;
}

.ant-btn.ant-btn-lg {
  height: 60px;
}

.main-heading {
  font-size: 4rem !important;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: 'Recursive', sans-serif !important;
}

.carousel .slider-wrapper {
  width: 40% !important;
}

.carousel  {
  margin: 4rem 0 !important;
}

.content-style{
  padding: 20px;
  max-width: 1650px;
  width: 100%;/* Set the maximum width of the content */
  margin: 0 auto; /* Center the content horizontally */
}

@media (max-width: 600px) {
  .ant-card {
    width: 100% !important;
  }
  .content-style{
    margin: 0 10px;
  }

  .carousel .slider-wrapper {
    width: 70% !important;
  }

}