body {
  margin: 0;
  font-family: "Times New Roman" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h3, h2 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: normal !important;
  font-family: 'Open Sans', sans-serif !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-80 {
  width: 80% !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.text-center {
  text-align: center;
}

.ant-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  padding: 15px;
  margin-top: 3rem;
}

.ant-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, -4px);
          transform: translate(0, -4px);
}

label {
  display: block !important;
}

.select-custom {
  width: 100%;
  padding: 10px;
}

.select-custom .ant-select-arrow {
  margin-right: 10px !important;
}

.button {
  padding: 15px;
}

.ant-btn.ant-btn-round.ant-btn-lg {
  border-radius: 40px;
  -webkit-padding-start: 60px;
          padding-inline-start: 60px;
  -webkit-padding-end: 60px;
          padding-inline-end: 60px;
  font-size: large;
}

.ant-btn.ant-btn-lg {
  height: 60px;
}

.main-heading {
  font-size: 4rem !important;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: 'Recursive', sans-serif !important;
}

.carousel .slider-wrapper {
  width: 40% !important;
}

.carousel  {
  margin: 4rem 0 !important;
}

.content-style{
  padding: 20px;
  max-width: 1650px;
  width: 100%;/* Set the maximum width of the content */
  margin: 0 auto; /* Center the content horizontally */
}

@media (max-width: 600px) {
  .ant-card {
    width: 100% !important;
  }
  .content-style{
    margin: 0 10px;
  }

  .carousel .slider-wrapper {
    width: 70% !important;
  }

}
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    /*background-color: #f0f0f0;*/
    border-radius: 10px;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.modal {
    position: relative;
    background-color: white;
    padding: 20px;
    z-index: 10000;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }

  .generated-image {
    max-width: 100%;
    height: auto;
  }

  .disabled-button {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }

  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    margin: 0 auto;
  }

@-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

@keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

form {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
  }

input,
  textarea,
  button {
    padding: 10px;
    font-size: 16px;
  }

button {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }

button:hover {
    background-color: #45a049;
  }

label {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    display: block;
  }

input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

h3 {
    font-size: 22px;
    margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

label {
  margin-bottom: 10px;
}

Input {
  width: 100%;
}

.pages-ul .common-heading {
  text-align: unset !important;
}
/*.App {*/
/*  text-align: center;*/
/*}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    /*background-color: #f0f0f0;*/
    border-radius: 10px;
}

.generated-image {
    max-width: 100%;
    height: auto;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    margin: 0 auto;
}

.custom-bullet-icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
    color: #1890ff; /* Change the color as needed */
}



form {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
}

input,
textarea,
button {
    padding: 10px;
    font-size: 16px;
}

label {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    display: block;
}

input,
textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.options h2 {
    font-size: 22px;
    text-align: center;
    font-weight: 500 !important;
}

.options .option--heading, .custom-book--heading, .confirmation-code-heading {
    font-size: 30px;
    font-weight: 800 !important;
}

.confirmation-code-heading {
    margin-bottom: 2.5rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

.card-header {
    margin-top: 3rem !important;
}

.card-main {
    padding-top: 30px;
}


Select {
    width: 100%;
}

.custom-book {
    margin: 4rem 0;
}

.custom-book--heading, .option--heading, .common-heading {
    text-align: center;
    font-family: 'Recursive', sans-serif !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
