.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    /*background-color: #f0f0f0;*/
    border-radius: 10px;
}

.generated-image {
    max-width: 100%;
    height: auto;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

.custom-bullet-icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
    color: #1890ff; /* Change the color as needed */
}



form {
    display: grid;
    gap: 10px;
}

input,
textarea,
button {
    padding: 10px;
    font-size: 16px;
}

label {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    display: block;
}

input,
textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.options h2 {
    font-size: 22px;
    text-align: center;
    font-weight: 500 !important;
}

.options .option--heading, .custom-book--heading, .confirmation-code-heading {
    font-size: 30px;
    font-weight: 800 !important;
}

.confirmation-code-heading {
    margin-bottom: 2.5rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

.card-header {
    margin-top: 3rem !important;
}

.card-main {
    padding-top: 30px;
}


Select {
    width: 100%;
}

.custom-book {
    margin: 4rem 0;
}

.custom-book--heading, .option--heading, .common-heading {
    text-align: center;
    font-family: 'Recursive', sans-serif !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}